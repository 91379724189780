@import '../../../styles/variables';

.container {
  width: 265px;
  min-width: 265px;
  margin-right: 20px;
  .header {
    padding: 8px 10px;
    border-radius: 6px;
    @include transition;
    margin-bottom: 10px;
    position: relative;
    h2 {
      @include title-3;
      text-transform: uppercase;
      font-size: 14px;
      padding: 0px 20px;
      text-align: center;
      margin: 0px;
    }
    &:hover {
      background-color: rgba($primary-color, 0.2);
      button {
        opacity: 1;
        pointer-events: all;
      }
    }
    button {
      @include btn;
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 20px;
      opacity: 0;
      pointer-events: none;
    }
  }
  .content {
    position: relative;
    width: 100%;
    border-radius: 6px;
    background-color: $white;
    box-shadow: $shadow;
    min-height: calc(100vh - 220px);
    .no-data {
      height: calc(100vh - 220px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}