@import '../../styles/variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  .logo {
    @include btn;
    @include transition;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 50px;
    }
    h2 {
      @include title-2;
      font-size: 30px;
      margin: 0px;
      margin-left: 5px;
    }
  }
  .content {
    @include animation-mixin-y(slideInLogin, 50px, 0);
    animation-name: slideInLogin;
    animation-duration: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo{
      display: flex;
      align-items: center;
      img {
        width: 200px;
      }
    }
    .message {
      margin: 20px;
      color: #999EB0;
      display: flex;
      align-items: center;
      flex-direction: column;
      p {
        margin: 10px;
        text-align: center;
        max-width: 220px;
      }
      // @media only screen and (min-width: $xs-screen) {
      //   flex-direction: row;
      //   justify-content: center;
      //   p {
      //     max-width: 100%;
      //   }
      // }

    }
  }
}