@import '../../../styles/variables';

.container {
  position: absolute;
  top: 140px;
  left: 20px;
  width: 330px;
  .types {
    padding: 15px;
    color: $white;
    background-color: $primary-color;
    box-shadow: $shadow;
    border-radius: 6px;
    label {
      opacity: 0.6;
    }
    .type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0px 0px 0px;
      h2 {
        @include title-3;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 22px;
        margin: 0px;
      }
      button {
        @include btn;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        padding: 5px;
        color: $white;
        border-radius: 20px;
        &.active {
          transform: rotate(180deg);
        }
        &:hover {
          background-color: rgba($dark, 0.5);
        }
      }
    }
    .select {
      @include transition;
      overflow: hidden;
      ul {
        padding: 0px;
        list-style: none;
        button {
          @include btn;
          @include title-3;
          text-transform: uppercase;
          margin: 5px 0px;
          font-size: 22px;
          padding: 5px 0px;
          color: rgba($white,0.3);
          &:hover {
            color: rgba($white,0.6);
          }
        }
      }
    }
  }
  .properties {
    list-style: none;
    padding: 5px 10px;
    background-color: $white;
    box-shadow: $shadow;
    border-radius: 6px;
    width: 100%;
    max-height: calc(100vh - 320px);
    overflow: auto;
    @include scrollbar;
    button {
      @include btn;
      @include title-3;
      text-transform: uppercase;
      margin: 5px 0px;
      font-size: 16px;
      border-radius: 6px;
      padding: 10px 0px;
      width: 100%;
      color: $white;
      background-color: rgba($primary-color, 0.3);
      &:hover {
        background-color: rgba($primary-color, 0.5);
      }
      &.active {
        background-color: rgba($primary-color, 1);
      }
    }
  }
}