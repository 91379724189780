@import '../../styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .col-img {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
      width: 55%;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../../assets/images/login.png');
    }
  }
  .col-form {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: $md-screen) {
      width: 45%;
    }
    form {
      width: 90%;
      max-width: 400px;
      min-height: 100%;
      padding: 0px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title {
        text-transform: uppercase;
        text-align: center;
        font-family: "Anybody-Bold";
        line-height: 90%;
        .name {
          font-size: 36px;
          color: $primary-color;
          letter-spacing: 4px;
          line-height: 39px;
          font-family: "Anybody-Bold";
        }
        span {
          font-size: 12px;
          color: $dark;
          font-family: "Ubuntu-Regular";
        } 
      }
      .btn-container {
        margin: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        &.reset {
          justify-content: center;
          a {
            text-decoration: none;
          }
        }
        .forgot {
          @include btn;
          @include label;
          padding: 0px;
          margin-top: 10px;
          color:rgba($dark , 0.4);
          text-decoration: underline;
          &:hover {
            color: $dark
          }
        }
      }
      .message {
        min-height: 36px;
        text-align: center;
        color: $primary-color;
        .error {
          color: $danger;
        }
      }
    }
  }
}