@import '../../../styles/variables';

.container {
  @include transition;
  position: absolute;
  right: 50px;
  top: 90px;
  background-color: $white;
  height: calc(100vh - 200px);
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: 15px;
  width: 300px;
  transform: translateX(400px);
  opacity: 0;
  &.active {
    opacity: 1;
    transform: translateX(0px);
  }
  .bar {
    height: 11px;
    width: 300px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .close {
    @include btn;
    padding: 5px 8px;
    float: right;
    text-transform: uppercase;
    @include text-button;
    color: rgba($dark, 0.4)
  }
  .title {
    display: block;
    margin: 15px 15px 0px 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba($dark, 0.1);
    h3 {
      margin: 0px;
    }
    .link {
      margin-top: 8px;
      display: flex;
      align-items: center;
      a {
        font-size: 14px;
        color: $dark;
      }
    }
  }
  .categories {
    max-height: calc(100vh - 300px);
    overflow: auto;
    .category {
      margin: 15px 15px 0px 15px;
      border-bottom: 1px solid rgba($dark, 0.1);
      p {
        margin: 0px;
        text-transform: capitalize;
        color: rgba($dark, 0.6);
        span {
          text-transform: lowercase;
        }
      }
      ul {
        padding: 0px;
        margin: 5px 0px 15px 0px;
        li {
          list-style: none;
          a {
            color: $dark;
          }
        }
      }
    }
  }
}