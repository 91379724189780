@import '../../styles/variables';

.container {
  position: relative;
  .logo {
    @include btn;
    @include transition;
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 20px;
    top: -100px;
    &.active {
      top: 20px;
    }
    h2 {
      @include title-2;
      margin: 0px;
      margin-left: 5px;
    }
  }
  .title {
    @include transition;
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 20px;
    top: -100px;
    &.active {
      top: 80px;
    }
    h1 {
      @include title-2;
      font-weight: 400;
      font-size: 32px;
      line-height: 46px;
      margin: 0px;
    }
  }
  .logout {
    @include btn;
    @include transition;
    @include text;
    text-transform: uppercase;
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    top: -100px;
    color: $primary-color;
    &.active {
      top: 20px;
    }
    p {
      margin: 0px 0px 0px 5px;
    }
  }
  .nav {
    @include transition;
    position: fixed;
    width: 100%;
    z-index: 100;
    left: 0px;
    display: flex;
    justify-content: center;
    bottom: -100px;
    &.active {
      bottom: 0px;
    }
    nav {
      position: relative;
      display: flex;
      justify-content: center;
      background: #FFFFFF;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      box-shadow: 0px -4px 51px rgba(235, 236, 237, 0.8);
      padding: 20px 40px;
      .indicator {
        @include transition;
        position: absolute;
        top:0px;
        left: 40px;
        width: 160px;
        height: 10px;
        background-color: $primary-color;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      .link {
        @include title-2;
        color: $dark;
        display: block;
        text-align: center;
        font-size: 16px;
        text-decoration: none;
        width: 160px;
        opacity: 0.3;
        &.selected {  
          opacity: 1;
        }
      }
    }
  }
}