@import './variables';

body, html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  color: $black;
  font-family: "Ubuntu-Regular" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  box-sizing: border-box;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

.app-container {
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
}

.rc-slider {
  margin-top: -8px;  
  .rc-slider-track {
    background-color: transparent;
  }

  .rc-slider-rail {
    background-color: #d7d7d7;
    height: 14px;
  }

  .rc-slider-handle {
    position: absolute;
    margin-top: 0px;
    background-color: $primary-color;
    border: solid 0px transparent;
  }
  .rc-slider-handle-dragging {
    box-shadow: 0 0 5px rgba($primary-color , 0.8);
    cursor: grabbing;
  }
}