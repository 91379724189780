@import '../../../../styles/variables';

.container {
  position: relative;
  .line {
    left: 0px;
    position: absolute;
    border-bottom: 1px dashed $primary-color;
  }
  .year {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    @include btn;
    background-color: $white;
    width: 70px;
    text-align: left;
    padding: 5px 10px;
    box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.245028);
    border-radius: 6px;
    transform: translate(6px,-10px);
    color: rgba($dark, 0.4);
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}