@import '../../styles/variables';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
 .map {
    position: absolute;
    width: 100%;
    height: 100%;
    @include transition;
    @include animation-fade(slide);
    animation-name: slide;
    animation-duration: 0.5s;
  }
  .select-map {
    height: 100%;
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .btn-container {
      background-color: $bg-color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 5px;
      border-radius: 5px;
      margin-right: 20px;
      button {
        @include btn;
        img {
          max-width: 82px;
          &.France {
            transform: translateX(-10px)
          }
        }
        font-family: "Ubuntu-Regular";
        p {
          margin-top: 5px;
        }
        &.not-active {
          opacity: 0.3;
        }
        &:hover {
          opacity: 0.4;
        }
      }
    }
  }
}