@import '../../../styles/variables';

.container {
  @include transition;
  @include scrollbar;
  position: relative;
  width: 280px;
  height: calc(100vh - 260px);
  top: 160px;
  left: 20px;
  z-index: 3;
  background-color: $white;
  border-radius: 15px;
  box-shadow: 0px -4px 51px rgba(235, 236, 237, 0.8);
  transform: translateX(-300px);
  overflow-y: auto;
  &.active {
    transform: translateX(0px);
  }
  section {
    margin: 15px 20px;
    a {
      color: $dark;
    }
    &:not(:last-child) {
      margin: 15px 20px 20px 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba($dark, 0.2);
    }
    label {
      @include title-3;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    h3 {
      font-size: 16px;
      margin: 15px 0px 0px 0px;
    }
    h5 {
      margin: 4px 0px 10px;
      font-size: 12px;
      opacity: 0.7;
    }
    .stats {
      display: flex;
      flex-direction: column;
      .stat {
        margin-bottom: 20px;
        .bar {
          width: 100%;
          height: 12px;
          margin-bottom: 2px;
          .value {
            height: 12px;
            border-radius: 6px;
            &.study {
              background-color: $study-color;
            }
            &.doctor {
              background-color: $doctor-color;
            }
            &.project {
              background-color: $project-color;
            }
            &.drug {
              background-color: $drug-color;
            }
          }
        }
        .infos {
          display: flex;
          justify-content: space-between;
          p {
            margin: 0px;
            &:last-child {
              @include title-3;
              letter-spacing: 1px;
              opacity: 0.5;
              text-transform: uppercase;
            }
          }
        }
      }
    }
    .links {
      margin-top: 20px;
      p {
        font-weight: 600;
        margin: 10px 0px;
      }
      &.service {
        a {
          color: #A491D7;
        }
      }
      &.study {
        a {
          color: $study-color;
        }
      }
      &.doctor {
        a {
          color: $doctor-color;
        }
      }
      &.project {
        a {
          color: $project-color;
        }
      }
      &.drug {
        a {
          color: $drug-color;
        }
      }
    }
  }
}