@import '../../../styles/variables';

.container {
  @include scrollbar;
  position: absolute;
  top: 50px;
  left: 370px;
  width: calc(100% - 370px);
  height: calc(100vh - 120px);
  padding: 20px;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .add {
    display: flex;
    align-items: center;
    .select-container {
      @include transition;
      .select {
        box-shadow: $shadow;
        background-color: $white;
        padding: 2px;
        border-radius: 3px;
        width: 250px;
        margin-right: 10px;
      }
    }
    button {
      @include btn;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      border-radius: 3px;
      color: $white;
      background-color: $primary-color;
      font-size: 30px;
    }
  }
}