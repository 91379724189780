@import '../../../styles/variables';

.container {
  padding: 10px;
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px dashed rgba(79, 60, 200, 0.3);
  }
  label {
    @include title-3;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
  }
  .string {
    @include text;
    color: rgba($dark, 0.7);
    p {
      margin: 10px 0px 0px 0px;
    }
  }
  .number {
    @include text;
    color: $dark;
    p {
      @include title-3;
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      margin: 10px 0px 0px 0px;
    }
  }
  .url {
    overflow: hidden;
    color: #0EAF9C;
    display: flex;
    align-items: center;
    margin-top: 8px;
    a {
      display: flex;
      align-items: center;
      color: #0EAF9C;
    }
  }
  .date {
    @include text;
    color: $dark;
    display: flex;
    align-items: center;
    margin-top: 8px;
    svg {
      color: $primary-color;
    }
    p {
      color: rgba($dark, 0.7);
      margin: 0px 0px 0px 10px;
    }
  }
  .array {
    display: flex;
    flex-wrap: wrap;
    p {
      font-size: 12px;
      background-color: rgba($primary-color, 0.2);
      margin: 6px 6px 0px 0px;
      padding: 3px 5px;
      border-radius: 3px;
    }
  }
}