@import '../../../../styles/variables';

.container {
  position: absolute;
  top: 0px;
  margin: 10px 0px;
  @include transition;
  .step {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    height: 20px;
    cursor: pointer;
    pointer-events: all;
    z-index: 400;
    @include transition;
  }
  .tooltip {
    position: fixed;
    width: 240px;
    z-index: 500;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.245028);
    border-radius: 6px;
    padding: 10px;
    .dates {
      display: flex;
      justify-content: space-between;
      color: rgba($dark, 0.5);
      margin-bottom: 4px;
      p {
        margin: 0px;
      }
    }
    p {
      margin: 5px 0px;
    }
  }
}