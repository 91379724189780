@import '../../../styles/variables';

@mixin node {
  transition: all 0.25s ease-in-out;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0px;
  top: 0px;
  // width: 40px;
  // height: 40px;
  // border-radius: 30px;
  margin: 10px;
  cursor: pointer;
  &.study {
    background-color: $study-color;
    outline: 6px solid rgba($study-color , 0);
    &:hover {
      outline: 6px solid rgba($study-color , 0.5);
    }
  }
  &.doctor {
    background-color: $doctor-color;
    outline: 6px solid rgba($doctor-color , 0);
    &:hover {
      outline: 6px solid rgba($doctor-color , 0.5);
    }
  }
  &.project {
    background-color: $project-color;
    outline: 6px solid rgba($project-color , 0);
    &:hover {
      outline: 6px solid rgba($project-color , 0.5);
    }
  }
  &.drug {
    background-color: $drug-color;
    outline: 6px solid rgba($drug-color , 0);
    &:hover {
      outline: 6px solid rgba($drug-color , 0.5);
    }
  }
  p {
    width: 140px;
    font-size: 14px;
    margin: 0px;
    font-weight: bold;
    text-align: center;
  }
}

.map {
  transform: scale(1);
  @include animation-scale(slideInScale, 0, 1);
  animation: slideInScale 0.2s linear ;
  animation-fill-mode: forwards;
  canvas {
    max-width: 100%;
  }
  circle {
    cursor: pointer;
  }
  .back {
    position: absolute;
    font-size: 14px;
    top: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    img {
      width: 20px;
    }
  }
  .city {
    transition: all 0.25s ease-in-out;
    position: absolute;
    width: 450px;
    height: 450px;
    border-radius: 250px;
    background:rgba(#F5F6FB, 0.3);
    border: 1px dashed $dark;
    transform: scale(0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: auto;
    opacity: 0;
    &.isActive {
      transition: all 0.75s ease-in-out;
      opacity: 1;
      transform: scale(1);
    }
    .label {
      min-width: 100px;
      text-align: center;
      font-weight: bold;
      padding: 10px;
      z-index: 1;
      background-color: $dark;
      border-radius: 20px;
      color: $white;
      transform: translateY(-220px)
    }
    .center {
      transition: all 0.25s ease-in-out;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 40px;
      height: 40px;
      border-radius: 30px;
      margin: 10px;
      cursor: pointer;
      border: 6px solid rgba($white , 0.8);
      &:hover {
        border: 6px solid rgba($white , 0.5);
        cursor: pointer;
      }
      p {
        width: 140px;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        transform: translate(-55px , 24px)
      }
    }
  }
  .selected-center {
    transition: all 0.25s ease-in-out;
    position: absolute;
    width: 450px;
    height: 450px;
    border-radius: 250px;
    background:rgba(#F5F6FB, 0.3);
    border: 1px dashed $primary-color;
    transform: scale(0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: auto;
    opacity: 0;
    &.isActive {
      transition: all 0.75s ease-in-out;
      opacity: 1;
      transform: scale(1);
    }
    .label {
      position: absolute;
      font-weight: bold;
      padding: 10px;
      z-index: 1;
      background-color: $white;
      border-radius: 20px;
      color: $dark;
      cursor: pointer;
      transform: translateY(-220px)
    }
    .node {
      @include node;
    }
    .service {
      @include transition;
      transition: all 0.25s ease-in-out;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 50px;
      height: 50px;
      border-radius: 35px;
      margin: 10px;
      cursor: pointer;
      border: 6px solid transparent;
      outline: 6px solid transparent;;
      &:hover {
        outline: 6px solid #c2b5e7;
      }
      p {
        width: 140px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        text-shadow: 1px 1px 2px $white, 0 0 1em #E3E3E3, 0 0 1em #E3E3E3;
        transform: translate(-50px , 36px);
      }
    }
  }
  .selected-service {
    transition: all 0.25s ease-in-out;
    position: absolute;
    width: 450px;
    height: 450px;
    border-radius: 250px;
    background:rgba(#F5F6FB, 0.3);
    border: 1px dashed $primary-color;
    transform: scale(0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: auto;
    opacity: 0;
    &.isActive {
      transition: all 0.75s ease-in-out;
      opacity: 1;
      transform: scale(1);
    }
    .label {
      position: absolute;
      font-weight: bold;
      padding: 10px;
      z-index: 1;
      background-color: $white;
      border-radius: 20px;
      color: $dark;
      transform: translateY(-220px)
    }
    .node {
      @include node;
    }
  }
}