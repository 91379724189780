@import '../../../styles/variables';

.container {
  position: absolute;
  width: 100%;
  height: 100vh;
  @include transition;
  #solid {
    opacity:0.5;
  }
  circle {
    cursor: pointer;
    box-shadow: $shadow;
  }
}

.select {
  position: absolute;
  top: 134px;
  left: 22px;
  z-index: 300;
  width: 240px;
  box-shadow: $shadow;
}

.legend {
  position: absolute;
  top: 190px;
  left: 22px;
  z-index: 300;
  .item {
    padding: 8px 20px;
    min-width: 170px;
    border-radius: 8px;
    margin-bottom: 10px;
    border: none;
    p {
      @include text-button;
      text-transform: uppercase;
      color: $white;
      text-align: center;
      margin: 0px;
    }
  }
  button {
    cursor: pointer;
    &.item {
      background-color: transparent;
      p {
        color: rgba($dark, 0.6);
      }
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}