@import '../../../styles/variables';

.container {
  position: absolute;
  top: 140px;
  left: 20px;
  width: 330px;
  z-index: 200;
  .types {
    list-style: none;
    padding: 5px 10px;
    background-color: $white;
    box-shadow: $shadow;
    border-radius: 6px;
    width: 100%;
    max-height: calc(100vh - 320px);
    overflow: auto;
    @include scrollbar;
    .type-item {
      @include btn;
      @include title-3;
      text-transform: uppercase;
      margin: 5px 0px;
      font-size: 16px;
      border-radius: 6px;
      padding: 10px 0px;
      width: 100%;
      color: $white;
      button {
        @include btn;
        @include title-3;
        font-size: 16px;
        text-transform: uppercase;
        color: $white;
        width: 100%;
        text-align: center;
      }
      .check {
        position: relative;
        margin-left: 10px;
        width: 100%;
        .toggle {
          width: 30px;
          height: 30px;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          right: 15px;
          top: -23px;
          font-size: 20px;
          div:first-child {
            @include transition;
          }
          .active {
            transform: rotate(180deg);
          }
        }
        button {
          @include btn;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          p {
            @include text;
            font-size: 12px;
            margin: 3px 5px;
            text-align: left;
            color: $white;
          }
        }
      }
    }
  }
}

.filters {
  position: absolute;
  top: 85px;
  left: 300px;
  z-index: 200;
  height: 40px;
  display: flex;
  align-items: center;
  &.active {
    box-shadow: 0px 10px 28px #EBECED;
  }
  .add {
    @include btn;
    @include text-button;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 5px 8px;
    margin-right: 10px;
    border-radius: $radius;
    background-color: $primary-color;
    color: $white;
    svg {
      margin-left: 5px;
    }
  }
  .search {
    width: 200px;
  }
}