@import '../../../styles/variables';

.container {
  @include page-container;
  z-index: 0;
  .actions {
    position: absolute;
    background-color: #F3F4FB;
    border-top-left-radius: 30px;
    top: 130px;
    right: 0px;
    width: calc(100% - 380px);
    height: 49px;
    z-index: 200;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      @include btn;
      @include text-button;
      color: rgba($dark, 0.6);
      text-transform: uppercase;
      padding: 5px 8px;
      margin-right: 10px;
      border-radius: $radius;
      border: 1px solid transparent;
      &:hover {
        background-color: rgba($dark, 0.2);
        border: 1px solid rgba($dark, 0.6);
      }
      &.btn {
        color: $primary-color;
        border: 1px solid $primary-color;
        &:hover {
          background-color: rgba($primary-color, 0.2);
        }
        &.active {
          background-color: $primary-color;
          color: $white;
        }
      }
    }
    .slider {
      width: 200px;
      margin-left: 20px;
    }
  } 
  .content {
    position: relative;
    background-color: #F3F4FB;
    width: calc(100% - 380px);
    margin-top: 130px;
    left: 380px;
    height: calc(100% - 210px);
    overflow: auto;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    @include scrollbar;
    .timelines {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 100;
      height: auto;
    }
    .dates {
      position: absolute;
      top: 0px;
      height: 100%;
      display: flex;
      pointer-events: none;
      .month {
        @include transition;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        // border-left: 1px solid rgba(31, 33, 81, 0.10);
        p {
          background-color: $white;
          z-index: 304;
          font-weight: bold;
          text-transform: uppercase;
          color:rgba($dark, 0.6);
          width: 100%;
          text-align: center;
          height: 28px;
          overflow: hidden;
          margin: 0px;
          padding: 5px;
          border-left: 1px solid rgba(31, 33, 81, 0.10);
          &.year {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            color: $primary-color;
          }
        }
        &.is-active {
          border-left: 1px solid rgba($primary-color, 0.8);
          p {
            padding: 5px;
            color: $primary-color;
          }
        }
      }
    }
    .plannings {
      width: 100%;
    }
  }
}