@mixin content {
  position: relative;
  width: 90%;
  @media only screen and (min-width: $md-screen) {
    width: 96%;
  }
  @media only screen and (min-width: $lg-screen) {
    width: 90%;
  }
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}



@mixin animation-mixin-x($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateX($from); opacity: 0;}
    100% {transform: translateX($to); opacity: 1;}
  }
}

@mixin animation-scale($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: scale($from); opacity: 0;}
    100% {transform: scale($to); opacity: 1;}
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin animation-fade($name) {
  @keyframes #{$name} {
    0% { opacity: 0;}
    100% { opacity: 1;}
  }
}

@mixin btn {
	cursor: pointer;
  padding: 0px;
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  margin: 0px;
  outline: none;
  border: none;
}

@mixin page-container {
  position: absolute;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  top: 0px;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
}

$drug-color: #E479A9;
$study-color: #F18C76;
$question-color: #FFA56D;
$doctor-color: #8280EA;
$center-color: #9E7CFF;
$project-color: #04c2f2;
$doc-color: #6DC5C3;

@mixin study {
  background: radial-gradient(96% 96% at 70.4% 31.2%, $study-color 0%, rgba(0, 2, 16, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(0deg, $study-color, $study-color);
  box-shadow: 10px 16px 20px 0px $study-color inset;
  box-shadow: 0px -20px 50px 0px $study-color inset;
  box-shadow: 0px 4px 40px 0px $study-color;
}

@mixin doctor {
  background: radial-gradient(96% 96% at 70.4% 31.2%, #5160E2 0%, rgba(0, 2, 16, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(0deg, #8280EA, #8280EA);
  box-shadow: 10px 16px 20px 0px $doctor-color inset;
  box-shadow: 0px -20px 50px 0px $doctor-color inset;
  box-shadow: 0px 4px 40px 0px $doctor-color;
}

@mixin drug {
  background: radial-gradient(96% 96% at 70.4% 31.2%, $drug-color 0%, rgba(0, 2, 16, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(0deg, $drug-color, $drug-color);
  box-shadow: 10px 16px 20px 0px $drug-color inset;
  box-shadow: 0px -20px 50px 0px $drug-color inset;
  box-shadow: 0px 4px 40px 0px $drug-color;
}

@mixin question {
  background: radial-gradient(96% 96% at 70.4% 31.2%, $question-color 0%, rgba(0, 2, 16, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(0deg, $question-color, $question-color);
  box-shadow: 10px 16px 20px 0px $question-color inset;
  box-shadow: 0px -20px 50px 0px $question-color inset;
  box-shadow: 0px 4px 40px 0px $question-color;
}


@mixin center {
  background: radial-gradient(96% 96% at 70.4% 31.2%, $center-color 0%, rgba(0, 2, 16, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(0deg, $center-color, $center-color);
  box-shadow: 10px 16px 20px 0px $center-color inset;
  box-shadow: 0px -20px 50px 0px $center-color inset;
  box-shadow: 0px 4px 40px 0px $center-color;  
}

@mixin project {
  background: radial-gradient(96% 96% at 70.4% 31.2%, $project-color 0%, rgba(0, 2, 16, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(0deg, $project-color, $project-color);
  box-shadow: 10px 16px 20px 0px $project-color inset;
  box-shadow: 0px -20px 50px 0px $project-color inset;
  box-shadow: 0px 4px 40px 0px $project-color;   
}

@mixin doc {
  background: radial-gradient(96% 96% at 70.4% 31.2%, $doc-color 0%, rgba(0, 2, 16, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(0deg, $doc-color, $doc-color);
  box-shadow: 10px 16px 20px 0px $doc-color inset;
  box-shadow: 0px -20px 50px 0px $doc-color inset;
  box-shadow: 0px 4px 40px 0px $doc-color;   
}